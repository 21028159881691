export const de = {
  'noCookie:title': 'Cookies deaktiviert',
  'noCookie:desc':
    'Ihr Browser hat Cookies deaktiviert. Bitte stellen Sie sicher, dass Cookies aktiviert sind, und versuchen Sie es erneut.',

  'signup:google': 'Mit Gmail registrieren',
  'signup:apple': 'Mit Apple registrieren',
  'signup:microsoft': 'Mit Microsoft registrieren',

  'login:google': 'Mit Gmail anmelden',
  'login:apple': 'Mit Apple anmelden',
  'login:microsoft': 'Mit Microsoft anmelden',
  'login:title': 'Oder melden Sie sich mit einem xBack-Konto an',
  'login:email:placeholder': 'E-Mail Adresse',
  'login:email:required': 'E-Mail kann nicht leer sein',
  'login:email:invalid': 'Email ist ungültig',
  'login:password:placeholder': 'xBack-Kennwort',
  'login:password:required': 'Das Passwort darf nicht leer sein',
  'login:remember': 'Erinnern Sie sich an mich',
  'login:forget': 'Passwort vergessen?',
  'login:signIn': 'Anmelden',
  'login:cerateAccount': 'Ein neues xBack-Konto erstellen',
  'login:expired': 'Der Link ist abgelaufen...',
  'login:access':
    'Bitte gewähren Sie Zugang zu Ihren Kontodaten, wenn Sie sich in Ihr xBack-Konto einloggen möchten.',
  'signup:alert': 'Warnung',
  'signup:codetip': 'Der von Ihnen angegebene Einladungscode ist ungültig.',
  'signup:continue': 'Weiter',
  'signup:title': 'Oder erstellen Sie ein neues xBack-Konto',
  'signup:resend': 'Erneut senden',
  'signup:sendcode': 'Verifizierungscode senden',
  'signup:code:placeholder':
    'Der Verifizierungscode befindet sich möglicherweise in der Junk-Mail',
  'signup:code:required': 'Der Verifizierungscode darf nicht leer sein',
  'signup:password:placeholder':
    'Das Passwort muss mindestens 6 Zeichen lang sein',
  'signup:password:required': 'Das Passwort darf nicht leer sein',
  'signup:password:invalid': 'Das Passwort muss mindestens 6 Zeichen lang sein',
  'signup:inviteCode:placeholder': 'Einladungscode (Optional)',
  'signup:inviteCode:no': 'Ich habe keinen Code',
  'signup:inviteCode:has': 'Ich habe einen Einladungscode',
  'signup:signup': 'Anmelden',
  'signup:hasAccount':
    ' Sie haben bereits ein Konto? Melden Sie sich jetzt an.',
  'reset:email:label': 'E-Mail',
  'reset:email:placeholder': 'Ihre E-Mail Adresse',
  'reset:email:required': 'Email kann nicht leer sein',
  'reset:email:invalid': 'Email ist ungültig',
  'reset:resend': 'Erneut senden',
  'reset:sendcode': 'Verifizierungscode senden',
  'reset:code:label': 'Verifizierungscode',
  'reset:code:placeholder': 'Verifizierungscode, kann in der Junk-Mail sein',
  'reset:code:required': 'Verifizierungscode kann nicht leer sein',
  'reset:newpassword:label': 'Neues Passwort',
  'reset:newpassword:placeholder':
    'Passwörter müssen mindestens 6 Zeichen lang sein',
  'reset:newpassword:required': 'Das Passwort darf nicht leer sein',
  'reset:newpassword:invalid':
    'Passwörter müssen mindestens 6 Zeichen lang sein',
  'reset:password2:label': 'Bestätigen Sie Ihr Passwort',
  'reset:password2:placeholder':
    'Passwörter müssen mindestens 6 Zeichen lang sein',
  'reset:password2:required': 'Das Bestätigungspasswort darf nicht leer sein',
  'reset:password2:invalid':
    'Die eingegebenen Passwörter stimmen nicht überein',
  'reset:reset': 'Passwort zurücksetzen',
  'reset:back': 'Zurück zu ',
  'reset:login': 'Anmelden',
  'agree:click': 'Durch Klicken auf ',
  'agree:text1': 'erklären Sie sich mit unseren ',
  'agree:text2': ' und ',
  'agree:terms': 'Servicebedingungen',
  'agree:privacy': 'Datenschutzrichtlinien',
  'app:loading': 'Laden...',
  'app:welcome': 'Willkommen bei xBack',
  'success:error': 'Dritter Autorisierungsfehler',
  'success:success': 'Erfolgreich!',
  'success:tryAgain': 'Erneut versuchen',
  'success:downloadTitle': 'Jetzt xBack herunterladen ',
  'success:downloadText1':
    ' um in den Genuss von VIP-Belohnungen zu kommen, da Sie von einem unserer Mitglieder eingeladen worden sind.',
  'success:downloadText2':
    'Du kannst auch weitere Freunde einladen, der xBack-Community beizutreten, um weitere Belohnungen zu entdecken!',
  'success:coming': 'Komme bald',
  'success:redirected': 'Wenn Sie nicht automatisch weitergeleitet werden',
  'copyright:copyright': 'Urheberrecht',
  'copyright:allRights': ' xBack Inc. Alle Rechte vorbehalten.',
  'download:title1': 'xBack herunterladen',
  'download:title2': 'xBack Web-Anmeldung',
  'download:desc': 'xBack jetzt herunterladen'
};
