export const ar = {
  'noCookie:title': 'کوکی‌ها غیرفعال شدند',
  'noCookie:desc':
    ' شما کوکی ها را غیرفعال کرده است. لطفاً مطمئن شوید که کوکی‌ها فعال هستند و دوباره امتحان کنید.',

  'signup:google': 'تسجيل الدخول باستخدام Gmail',
  'signup:apple': 'تسجيل الدخول باستخدام Apple',
  'signup:microsoft': 'تسجيل الدخول باستخدام Microsoft',

  'login:google': 'التسجيل باستخدام Gmail',
  'login:apple': 'التسجيل باستخدام Apple',
  'login:microsoft': 'التسجيل باستخدام Microsoft',
  'login:title': 'أو تسجيل الدخول باستخدام حساب xBack',
  'login:email:placeholder': 'عنوان البريد الإلكتروني',
  'login:email:required': 'لا يمكن أن يكون البريد الإلكتروني فارغاً',
  'login:email:invalid': 'البريد الإلكتروني غير صالح',
  'login:password:placeholder': 'كلمة مرور xBack',
  'login:password:required': 'لا يمكن أن تكون كلمة المرور فارغة',
  'login:remember': 'تذكرني',
  'login:forget': 'هل نسيت كلمة المرور؟',
  'login:signIn': 'تسجيل الدخول',
  'login:cerateAccount': 'إنشاء حساب xBack جديد',
  'login:expired': 'انتهت صلاحية الرابط...',
  'login:access':
    'يرجى منح حق الوصول إلى تفاصيل حسابك إذا كنت ترغب في تسجيل الدخول إلى حساب xBack الخاص بك.',
  'signup:alert': 'تنبيه',
  'signup:codetip': 'رمز الدعوة الذي حددته غير صالح.',
  'signup:continue': 'متابعة',
  'signup:title': 'أو قم بإنشاء حساب xBack جديد',
  'signup:resend': 'إعادة إرسال',
  'signup:sendcode': 'إرسال رمز التحقق',
  'signup:code:placeholder': 'رمز التحقق، قد يكون في البريد غير الهام',
  'signup:code:required': 'لا يمكن أن يكون رمز التحقق فارغاً',
  'signup:password:placeholder': 'يجب ألا تقل كلمة المرور عن 6 أحرف',
  'signup:password:required': 'لا يمكن أن تكون كلمة المرور فارغة',
  'signup:password:invalid': 'يجب ألا تقل كلمة المرور عن 6 أحرف',
  'signup:inviteCode:placeholder': 'رمز الدعوة (اختياري)',
  'signup:inviteCode:no': 'ليس لدي رمز دعوة',
  'signup:inviteCode:has': 'لدي رمز دعوة',
  'signup:signup': 'قم بالتسجيل',
  'signup:hasAccount': 'هل لديك حساب بالفعل؟ سجّل الدخول الآن. ',
  'reset:email:label': 'البريد الإلكتروني',
  'reset:email:placeholder': 'بريدك الإلكتروني',
  'reset:email:required': 'البريد الإلكتروني غير صالح',
  'reset:email:invalid': 'البريد الإلكتروني غير صالح',
  'reset:resend': 'إعادة الإرسال',
  'reset:sendcode': 'إرسال رمز التحقق',
  'reset:code:label': 'رمز التحقق',
  'reset:code:placeholder': 'رمز التحقق، قد يكون في البريد غير الهام',
  'reset:code:required': 'لا يمكن أن يكون رمز التحقق فارغاً',
  'reset:newpassword:label': 'كلمة مرور جديدة',
  'reset:newpassword:placeholder': 'يجب ألا تقل كلمات المرور عن 6 أحرف',
  'reset:newpassword:required': 'لا يمكن أن تكون كلمة المرور فارغة',
  'reset:newpassword:invalid': 'يجب ألا تقل كلمات المرور عن 6 أحرف',
  'reset:password2:label': 'تأكيد كلمة المرور',
  'reset:password2:placeholder': 'يجب ألا تقل كلمات المرور عن 6 أحرف',
  'reset:password2:required': 'لا يمكن أن تكون كلمة المرور فارغة',
  'reset:password2:invalid': 'كلمات المرور المدخلة غير متطابقة',
  'reset:reset': 'إعادة تعيين كلمة المرور',
  'reset:back': 'العودة إلى',
  'reset:login': 'تسجيل الدخول',
  'agree:click': 'بالضغط على',
  'agree:text1': 'فإنك توافق على',
  'agree:text2': ' على',
  'agree:terms': 'شروط الخدمة',
  'agree:privacy': 'سياسة الخصوصية',
  'app:loading': 'جاري التحميل...',
  'app:welcome': 'مرحبًا بك في xBack',
  'success:error': 'خطأ في التفويض الثالث',
  'success:success': 'نجح!',
  'success:tryAgain': 'حاول مرة أخرى',
  'success:downloadTitle': 'قم بتنزيل xBack الآن',
  'success:downloadText1':
    'لتستمتع بمكافأة كبار الشخصيات حيث تمت دعوتك من قبل أعضائنا. ',
  'success:downloadText2':
    'يمكنك أيضًا دعوة المزيد من الأصدقاء للانضمام إلى مجتمع xBack، مع المزيد من المكافآت لاكتشافها!',
  'success:coming': 'قريباً',
  'success:redirected': 'إذا لم تتم إعادة توجيهك تلقائياً',
  'copyright:copyright': 'حقوق الطبع والنشر',
  'copyright:allRights': 'شركة xBack Inc. جميع الحقوق محفوظة.',
  'download:title1': 'تحميل xBack',
  'download:title2': 'تسجيل الدخول إلى الويب xBack',
  'download:desc': 'قم بتنزيل xBack الآن'
};
