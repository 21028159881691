import React from 'react';
import type { FC, ReactNode } from 'react';
import Logo from '@/assets/images/logo.png';
import './index.less';

interface IProps {
  children?: ReactNode;
}
const LogoComp: FC<IProps> = () => {
  return (
    <div className="logo-container">
      <img className="logo" src={Logo} alt="" />
    </div>
  );
};

export default LogoComp;
