export const ko = {
  'noCookie:title': '쿠키가 비활성화되었습니다.',
  'noCookie:desc':
    '귀하의 브라우저는 쿠키를 비활성화했습니다. 쿠키가 활성화되어 있는지 확인하고 다시 시도하십시오.',

  'signup:google': 'Google으로 가입',
  'signup:apple': 'Apple로 가입',
  'signup:microsoft': 'Microsoft으로 가입',

  'login:google': 'Google으로 로그인',
  'login:apple': 'Apple로 로그인',
  'login:microsoft': 'Microsoft으로 로그인',
  'login:title': '또는 xBack 계정으로 로그인',
  'login:email:placeholder': '이메일 주소',
  'login:email:required': '이메일이 비어 있을 수 없습니다.',
  'login:email:invalid': '이메일이 유효하지 않습니다.',
  'login:password:placeholder': 'xBack 비밀번호',
  'login:password:required': '비밀번호는 비워둘 수 없습니다.',
  'login:remember': '나를 기억해줘',
  'login:forget': '비밀번호를 잊으셨나요?',
  'login:signIn': '로그인',
  'login:cerateAccount': '새 xBack 계정 만들기',
  'login:expired': '링크가 만료되었습니다...',
  'login:access':
    'xBack 계정에 로그인하려면 계정 세부 정보에 대한 액세스 권한을 부여하세요.',
  'signup:alert': '알림',
  'signup:codetip': '지정한 초대 코드가 유효하지 않습니다.',
  'signup:continue': '계속하기',
  'signup:title': '또는 새 xBack 계정을 만드세요.',
  'signup:resend': '다시 보내기',
  'signup:sendcode': '인증 코드 보내기',
  'signup:code:placeholder': '인증 코드가 정크 메일에 있을 수 있습니다.',
  'signup:code:required': '인증 코드는 비워둘 수 없습니다.',
  'signup:password:placeholder': '비밀번호는 8자 이상이어야 합니다.',
  'signup:password:required': '비밀번호는 비워둘 수 없습니다.',
  'signup:password:invalid': '비밀번호는 8자 이상이어야 합니다.',
  'signup:inviteCode:placeholder': '초대 코드(선택 사항)',
  'signup:inviteCode:no': '초대 코드가 없습니다.',
  'signup:inviteCode:has': '초대 코드가 있습니다.',
  'signup:signup': '가입하기',
  'signup:hasAccount': '이미 계정이 있으신가요? 지금 로그인하세요.',
  'reset:email:label': '이메일',
  'reset:email:placeholder': '이메일 주소',
  'reset:email:required': '이메일은 비워 둘 수 없습니다.',
  'reset:email:invalid': '이메일이 유효하지 않습니다.',
  'reset:resend': '다시 보내기',
  'reset:sendcode': '인증 코드 보내기',
  'reset:code:label': '인증 코드',
  'reset:code:placeholder': '인증 코드, 정크 메일에 있을 수 있습니다.',
  'reset:code:required': '인증 코드는 비어 있을 수 없습니다.',
  'reset:newpassword:label': '새 비밀번호',
  'reset:newpassword:placeholder': '비밀번호는 8자 이상이어야 합니다.',
  'reset:newpassword:required': '비밀번호는 비워둘 수 없습니다.',
  'reset:newpassword:invalid': '비밀번호는 8자 이상이어야 합니다.',
  'reset:password2:label': '비밀번호 확인',
  'reset:password2:placeholder': '비밀번호는 8자 이상이어야 합니다.',
  'reset:password2:required': '비밀번호는 비워둘 수 없습니다.',
  'reset:password2:invalid': '입력한 비밀번호가 일치하지 않습니다.',
  'reset:reset': '비밀번호 재설정',
  'reset:back': '돌아가기',
  'reset:login': '로그인',
  'agree:click': '를 클릭하면',
  'agree:text1': '을 클릭하면 귀하는 당사의',
  'agree:text2': '및',
  'agree:terms': '서비스 약관',
  'agree:privacy': '개인정보 보호정책',
  'app:loading': '로드 중...',
  'app:welcome': 'xBack에 오신 것을 환영합니다.',
  'success:error': '세 번째 인증 오류',
  'success:success': '성공했습니다!',
  'success:tryAgain': '다시 시도하세요.',
  'success:downloadTitle': '지금 xBack 다운로드 ',
  'success:downloadText1':
    '를 다운로드하여 회원 초대를 받아 VIP 리워드를 즐기세요.',
  'success:downloadText2':
    '더 많은 친구를 초대하여 더 많은 보상을 받을 수 있는 xBack 커뮤니티에 가입할 수도 있습니다!',
  'success:coming': '출시 예정',
  'success:redirected': '자동으로 리디렉션되지 않은 경우',
  'copyright:copyright': '저작권',
  'copyright:allRights': 'xBack Inc. 모든 권리 보유.',
  'download:title1': 'xBack 다운로드',
  'download:title2': 'xBack 웹 로그인',
  'download:desc': '지금 xBack 다운로드'
};
