export const id = {
  'noCookie:title': 'Cookie dinonaktifkan',
  'noCookie:desc':
    'Browser Anda telah menonaktifkan cookie. Harap pastikan cookie diaktifkan dan coba lagi. ',

  'signup:google': 'Daftar dengan Gmail',
  'signup:apple': 'Daftar dengan Apple',
  'signup:microsoft': 'Daftar dengan Microsoft',

  'login:google': 'Masuk dengan Gmail',
  'login:apple': 'Masuk dengan Apple',
  'login:microsoft': 'Masuk dengan Microsoft',
  'login:title': 'Atau masuk dengan akun xBack',
  'login:email:placeholder': 'Alamat email',
  'login:email:required': 'Email tidak boleh kosong',
  'login:email:invalid': 'Email tidak valid',
  'login:password:placeholder': 'Kata sandi xBack',
  'login:password:required': 'Kata sandi tidak boleh kosong',
  'login:remember': 'Ingat saya',
  'login:forget': 'Lupa kata sandi?',
  'login:signIn': 'Masuk',
  'login:cerateAccount': 'Buat akun xBack baru',
  'login:expired': 'Tautan telah kedaluwarsa...',
  'login:access':
    'Mohon berikan akses ke detail akun Anda jika Anda ingin masuk ke akun xBack Anda.',
  'signup:alert': 'Peringatan',
  'signup:codetip': 'Kode undangan yang Anda tentukan tidak valid.',
  'signup:continue': 'Lanjutkan',
  'signup:title': 'Atau buat akun xBack baru',
  'signup:resend': 'Kirim ulang',
  'signup:sendcode': 'Kirim kode verifikasi',
  'signup:code:placeholder': 'Kode verifikasi, mungkin ada di email sampah',
  'signup:code:required': 'Kode Verifikasi tidak boleh kosong',
  'signup:password:placeholder':
    'Kata sandi minimal harus terdiri dari 6 karakter',
  'signup:password:required': 'Kata sandi tidak boleh kosong',
  'signup:password:invalid': 'Kata sandi harus minimal 6 karakter',
  'signup:inviteCode:placeholder': 'Kode undangan (Opsional)',
  'signup:inviteCode:no': 'Saya tidak punya',
  'signup:inviteCode:has': 'Saya memiliki Kode Undangan',
  'signup:signup': 'Daftar',
  'signup:hasAccount': ' Sudah punya akun? Masuk sekarang.',
  'reset:email:label': 'Email',
  'reset:email:placeholder': 'Alamat email Anda',
  'reset:email:required': 'Email tidak boleh kosong',
  'reset:email:invalid': 'Email tidak valid',
  'reset:resend': 'Kirim ulang',
  'reset:sendcode': 'Kirim kode verifikasi',
  'reset:code:label': 'Kode verifikasi',
  'reset:code:placeholder': 'Kode verifikasi, mungkin ada di email sampah',
  'reset:code:required': 'Kode Verifikasi tidak boleh kosong',
  'reset:newpassword:label': 'Kata sandi baru',
  'reset:newpassword:placeholder':
    'Kata sandi minimal harus terdiri dari 6 karakter',
  'reset:newpassword:required': 'Kata sandi tidak boleh kosong',
  'reset:newpassword:invalid':
    'Kata sandi minimal harus terdiri dari 6 karakter',
  'reset:password2:label': 'Konfirmasi kata sandi',
  'reset:password2:placeholder': 'Kata sandi harus minimal 6 karakter',
  'reset:password2:required': 'Konfirmasi kata sandi tidak boleh kosong',
  'reset:password2:invalid': 'Kata sandi yang dimasukkan tidak cocok',
  'reset:reset': 'Atur ulang kata sandi',
  'reset:back': 'Kembali ke ',
  'reset:login': 'Masuk',
  'agree:click': 'Dengan mengklik ',
  'agree:text1': ', Anda menyetujui ketentuan ',
  'agree:text2': ' dan ',
  'agree:terms': 'Ketentuan Layanan',
  'agree:privacy': 'Kebijakan Privasi',
  'app:loading': 'Memuat...',
  'app:welcome': 'Selamat datang di xBack',
  'success:error': 'kesalahan otorisasi ketiga',
  'success:success': 'Berhasil!',
  'success:tryAgain': 'Coba lagi',
  'success:downloadTitle': 'Unduh xBack sekarang ',
  'success:downloadText1':
    ' untuk menikmati hadiah VIP karena Anda telah diundang oleh anggota kami.',
  'success:downloadText2':
    'Anda juga dapat mengundang lebih banyak teman untuk bergabung dengan komunitas xBack, dengan lebih banyak hadiah yang bisa Anda temukan!',
  'success:coming': 'Segera hadir',
  'success:redirected': 'Jika Anda tidak dialihkan secara otomatis',
  'copyright:copyright': 'Hak Cipta',
  'copyright:allRights':
    ' xBack Inc. Semua hak cipta dilindungi undang-undang.',
  'download:title1': 'Unduh xBack',
  'download:title2': 'Login web xBack',
  'download:desc': 'Unduh xBack sekarang'
};
