import React, { Suspense, useLayoutEffect, useState } from 'react';
import { useRoutes } from 'react-router-dom';
import routes from './router';
import Intl from '@/components/intl';
import { FormattedMessage } from 'react-intl';
import NoCookie from './views/noCookie';

function App() {
  const [allowCookie, setAllowCookie] = useState(false);
  useLayoutEffect(() => {
    try {
      setAllowCookie(!!window.localStorage);
    } catch (error) {
      setAllowCookie(false);
    }
  }, []);

  const loading = (
    <div
      style={{
        position: 'fixed',
        width: '100%',
        height: '80vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <h2 style={{ fontSize: '36px', fontWeight: 700 }}>
        <FormattedMessage id="app:welcome" />
      </h2>
      <p style={{ fontSize: '20px' }}>
        <FormattedMessage id="app:loading" />
      </p>
    </div>
  );

  return (
    <div className="App">
      <Intl>
        {allowCookie ? (
          <Suspense fallback={loading}>
            <div className="main">{useRoutes(routes)}</div>
          </Suspense>
        ) : (
          <NoCookie />
        )}
      </Intl>
    </div>
  );
}

export default App;
