import React from 'react';
import type { FC, ReactNode } from 'react';
import Logo from '@/assets/images/logo.png';
import { FormattedMessage } from 'react-intl';
import './index.less';

interface IProps {
  children?: ReactNode;
}

const NoCookie: FC<IProps> = () => {
  return (
    <div className="noCookie-container">
      <img className="logo" src={Logo} alt="" />

      <div>
        <div className="noCookie-tip">
          <FormattedMessage id="noCookie:title" />
        </div>
        <div className="noCookie-contact">
          <FormattedMessage id="noCookie:desc" />
        </div>

        {/* <div className="noCookie-contact">
          If you still can not register account, please contact our customer
          service.
          <a className="contact-mail" href="mailto:go.xback@gmail.com">
            go.xback@gmail.com
          </a>
        </div> */}
      </div>
    </div>
  );
};

export default NoCookie;
