export const en = {
  'noCookie:title': ' Cookies disabled',
  'noCookie:desc':
    'Your browser has cookies disabled. Please make sure to enable cookies and try again.',

  'signup:google': 'Sign up with Gmail',
  'signup:apple': 'Sign up with Apple',
  'signup:microsoft': 'Sign up with Microsoft',

  'login:google': 'Login with Gmail',
  'login:apple': 'Login with Apple',
  'login:microsoft': 'Login with Microsoft',
  'login:title': 'Or sign in with xBack account',

  'login:email:placeholder': 'Email address',
  'login:email:required': 'Email can not be empty',
  'login:email:invalid': 'Email is invalid',

  'login:password:placeholder': 'xBack password',
  'login:password:required': 'Password can not be empty',

  'login:remember': 'Remember me',
  'login:forget': 'Forgot password?',
  'login:signIn': 'Sign in',
  'login:cerateAccount': 'Create a new xBack account',

  'login:expired': 'The link has expired...',
  'login:access':
    'Please grant access to your account details if you wish to login to your xBack account.',

  'signup:alert': 'Alert',
  'signup:codetip': 'The invitation code you specified is invalid.',
  'signup:continue': 'Continue',

  'signup:title': 'Or create a new xBack account',

  'signup:resend': 'Resend',
  'signup:sendcode': 'Send verification code',
  'signup:code:placeholder': 'Verification code, it may be in junk mail',
  'signup:code:required': 'Verification Code can not be empty',

  'signup:password:placeholder': 'Password must be at least 6 characters',
  'signup:password:required': 'Password can not be empty',
  'signup:password:invalid': 'Password must be at least 6 characters',

  'signup:inviteCode:placeholder': 'Invite code (Optional)',
  'signup:inviteCode:no': "I don't have one",
  'signup:inviteCode:has': 'I have an Invite Code',

  'signup:signup': 'Sign up',
  'signup:hasAccount': ' Already have an account? Sign in now.',

  'reset:email:label': 'Email',
  'reset:email:placeholder': 'Your email address',
  'reset:email:required': 'Email can not be empty',
  'reset:email:invalid': 'Email is invalid',

  'reset:resend': 'Resend',
  'reset:sendcode': 'Send verification code',
  'reset:code:label': 'Verification code',
  'reset:code:placeholder': 'Verification code, it may be in junk mail',
  'reset:code:required': 'Verification Code can not be empty',

  'reset:newpassword:label': 'New password',
  'reset:newpassword:placeholder': 'Passwords must be at least 6 characters',
  'reset:newpassword:required': 'Password can not be empty',
  'reset:newpassword:invalid': 'Passwords must be at least 6 characters',

  'reset:password2:label': 'Confirm password',
  'reset:password2:placeholder': 'Passwords must be at least 6 characters',
  'reset:password2:required': 'Confirm password can not be empty',
  'reset:password2:invalid': 'The passwords entered do not match',

  'reset:reset': 'Reset password',
  'reset:back': 'Back to ',
  'reset:login': 'Sign in',

  'agree:click': 'By clicking ',
  'agree:text1': ', you agree to our ',
  'agree:text2': ' and ',
  'agree:terms': 'Terms of Service',
  'agree:privacy': 'Privacy Policy',

  'app:loading': 'Loading...',
  'app:welcome': 'Welcome to xBack',

  'success:error': 'third authorization error',
  'success:success': 'Succeed!',
  'success:tryAgain': 'Try again',
  'success:downloadTitle': 'Download xBack now',
  'success:downloadText1':
    ' to enjoy VIP reward as you have been invited by our member.',
  'success:downloadText2':
    'You can also invite more friends to join xBack community, with more rewards to discover!',
  'success:coming': 'Coming Soon',
  'success:redirected': "If you aren't redirected automatically",

  'copyright:copyright': 'Copyright',
  'copyright:allRights': ' xBack Inc. All rights reserved.',

  'download:title1': 'Download xBack',
  'download:title2': 'xBack web login',
  'download:desc': 'Download xBack now'
};
