import { createSlice } from '@reduxjs/toolkit';

export interface IAccountState {
  accountInfo: null | {
    email: string;
    endUnix: number;
  };
  orderList: any[];
  activityCode: string | undefined;
  countryCode: string;
  language?: string;
}

const initialState: IAccountState = {
  accountInfo: null,
  orderList: [],
  activityCode: undefined,
  countryCode: 'IR',
  language: undefined
};

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    updateLanguage(state, { payload }) {
      state.language = payload;
    },
    updateAccount(state, { payload }) {
      state.accountInfo = payload;
    },
    updateOrder(state, { payload }) {
      state.orderList = payload;
    },
    updateActivityCode(state, { payload }) {
      state.activityCode = payload;
    },
    updateCountryCode(state, { payload }) {
      state.countryCode = payload;
    }
  }
});

export const {
  updateLanguage,
  updateAccount,
  updateOrder,
  updateActivityCode,
  updateCountryCode
} = accountSlice.actions;
export default accountSlice.reducer;
