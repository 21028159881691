export const th = {
  'noCookie:title': 'ปิดการใช้งานคุกกี้',
  'noCookie:desc':
    'เบราว์เซอร์ของคุณปิดการใช้งานคุกกี้ โปรดตรวจสอบให้แน่ใจว่าได้เปิดใช้งานคุกกี้แล้วลองอีกครั้ง',

  'signup:google': 'ลงทะเบียนด้วย Gmail',
  'signup:apple': 'ลงทะเบียนด้วย Apple',
  'signup:microsoft': 'ลงทะเบียนด้วย Microsoft',

  'login:google': 'เข้าสู่ระบบด้วย Gmail',
  'login:apple': 'เข้าสู่ระบบด้วย Apple',
  'login:microsoft': 'เข้าสู่ระบบด้วย Microsoft',
  'login:title': 'หรือเข้าสู่ระบบด้วยบัญชี xBack',
  'login:email:placeholder': 'ที่อยู่อีเมล',
  'login:email:required': 'อีเมลไม่สามารถเว้นว่างได้',
  'login:email:invalid': 'อีเมล์ไม่ถูกต้อง',
  'login:password:placeholder': 'รหัสผ่าน xBack',
  'login:password:required': 'รหัสผ่านต้องไม่เว้นว่าง',
  'login:remember': 'จดจำฉัน',
  'login:forget': 'ลืมรหัสผ่าน?',
  'login:signIn': 'เข้าสู่ระบบ',
  'login:cerateAccount': 'สร้างบัญชี xBack ใหม่',
  'login:expired': 'ลิงค์หมดอายุแล้ว...',
  'login:access':
    'โปรดให้สิทธิ์การเข้าถึงรายละเอียดบัญชีของคุณหากคุณต้องการเข้าสู่ระบบบัญชี xBack ของคุณ',
  'signup:alert': 'เตือน',
  'signup:codetip': 'รหัสคำเชิญที่คุณระบุไม่ถูกต้อง',
  'signup:continue': 'ดำเนินการต่อ',
  'signup:title': 'หรือสร้างบัญชี xBack ใหม่',
  'signup:resend': 'ส่งซ้ำ',
  'signup:sendcode': 'ส่งรหัสยืนยัน',
  'signup:code:placeholder': 'รหัสยืนยันอาจอยู่ในอีเมลขยะ',
  'signup:code:required': 'รหัสยืนยันต้องไม่เว้นว่าง',
  'signup:password:placeholder': 'รหัสผ่านต้องมีอย่างน้อย 6 ตัวอักษร',
  'signup:password:required': 'รหัสผ่านต้องไม่เว้นว่าง',
  'signup:password:invalid': 'รหัสผ่านต้องมีอย่างน้อย 6 ตัวอักษร',
  'signup:inviteCode:placeholder': 'รหัสเชิญ (ไม่บังคับ)',
  'signup:inviteCode:no': 'ฉันไม่มี',
  'signup:inviteCode:has': 'ฉันมีรหัสเชิญ',
  'signup:signup': 'ลงชื่อ',
  'signup:hasAccount': 'มีบัญชีอยู่แล้ว? เข้าสู่ระบบตอนนี้',
  'reset:email:label': 'อีเมล',
  'reset:email:placeholder': 'ที่อยู่อีเมลของคุณ',
  'reset:email:required': 'อีเมลไม่สามารถเว้นว่างได้',
  'reset:email:invalid': 'อีเมล์ไม่ถูกต้อง',
  'reset:resend': 'ส่งซ้ำ',
  'reset:sendcode': 'ส่งรหัสยืนยัน',
  'reset:code:label': 'รหัสยืนยัน',
  'reset:code:placeholder': 'รหัสยืนยันอาจอยู่ในอีเมลขยะ',
  'reset:code:required': 'รหัสยืนยันต้องไม่เว้นว่าง',
  'reset:newpassword:label': 'รหัสผ่านใหม่',
  'reset:newpassword:placeholder': 'รหัสผ่านต้องมีอย่างน้อย 6 ตัวอักษร',
  'reset:newpassword:required': 'รหัสผ่านต้องไม่เว้นว่าง',
  'reset:newpassword:invalid': 'รหัสผ่านต้องมีอย่างน้อย 6 ตัวอักษร',
  'reset:password2:label': 'ยืนยันรหัสผ่าน',
  'reset:password2:placeholder': 'รหัสผ่านต้องมีอย่างน้อย 6 ตัวอักษร',
  'reset:password2:required': 'ยืนยันรหัสผ่านต้องไม่เว้นว่าง',
  'reset:password2:invalid': 'รหัสผ่านที่ป้อนไม่ตรงกัน',
  'reset:reset': 'รีเซ็ตรหัสผ่าน',
  'reset:back': 'กลับไป',
  'reset:login': 'เข้าสู่ระบบ',
  'agree:click': 'โดยคลิก',
  'agree:text1': 'คุณเห็นด้วยกับเรา',
  'agree:text2': 'และ',
  'agree:terms': 'เงื่อนไขการให้บริการ',
  'agree:privacy': 'นโยบายความเป็นส่วนตัว',
  'app:loading': 'กำลังโหลด...',
  'app:welcome': 'ยินดีต้อนรับสู่ xBack',
  'success:error': 'ข้อผิดพลาดในการอนุญาตครั้งที่สาม',
  'success:success': 'สำเร็จ!',
  'success:tryAgain': 'ลองอีกครั้ง',
  'success:downloadTitle': 'ดาวน์โหลด xBack ทันที',
  'success:downloadText1':
    'เพื่อรับรางวัลวีไอพีตามที่คุณได้รับเชิญจากสมาชิกของเรา',
  'success:downloadText2':
    'คุณยังสามารถเชิญเพื่อนเพิ่มเติมให้เข้าร่วมชุมชน xBack พร้อมรางวัลมากมายให้ค้นพบ!',
  'success:coming': 'เร็วๆ นี้',
  'success:redirected': 'หากคุณไม่เปลี่ยนเส้นทางโดยอัตโนมัติ',
  'copyright:copyright': 'ลิขสิทธิ์',
  'copyright:allRights': 'xBack Inc. สงวนลิขสิทธิ์',
  'download:title1': 'ดาวน์โหลด xBack',
  'download:title2': 'เข้าสู่ระบบเว็บ xBack',
  'download:desc': 'ดาวน์โหลด xBack ทันที'
};
