export const HK_MO_TW = {
  'noCookie:title': 'Cookie 已停用',
  'noCookie:desc': '您的瀏覽器停用了 Cookie。請務必啟用 Cookie，然後重試。',

  'signup:google': '使用 Gmail 註冊',
  'signup:apple': '使用 Apple 註冊',
  'signup:microsoft': '使用 Microsoft 註冊',

  'login:google': '使用 Gmail 登录',
  'login:apple': '使用 Apple 登录',
  'login:microsoft': '使用 Microsoft 登录',
  'login:title': '或使用 xBack 帳號登入',
  'login:email:placeholder': '電子郵件地址',
  'login:email:required': '郵箱不能為空',
  'login:email:invalid': '電子郵件無效',
  'login:password:placeholder': '密碼',
  'login:password:required': '密碼不能為空',
  'login:remember': '記住帳號',
  'login:forget': '忘記密碼？',
  'login:signIn': '登入',
  'login:cerateAccount': '建立一個新的 xBack 帳戶',
  'login:expired': '連結已過期...',
  'login:access':
    '如果您希望登入您的 xBack 帳戶，請授予對您帳戶詳細資訊的存取權限。',
  'signup:alert': '警報',
  'signup:codetip': '您指定的邀請碼無效。',
  'signup:continue': '繼續',
  'signup:title': '或建立一個新的 xBack 帳戶',
  'signup:resend': '重發',
  'signup:sendcode': '發送驗證碼',
  'signup:code:placeholder': '驗證碼，可能在垃圾郵件中',
  'signup:code:required': '驗證碼不能為空',
  'signup:password:placeholder': '密碼必須至少6個字符',
  'signup:password:required': '密碼不能為空',
  'signup:password:invalid': '密碼必須至少6個字符',
  'signup:inviteCode:placeholder': '邀請碼（可選）',
  'signup:inviteCode:no': '我沒有',
  'signup:inviteCode:has': '我有邀請碼',
  'signup:signup': '註冊',
  'signup:hasAccount': '已經有帳戶？ 立即登入。',
  'reset:email:label': '電子郵件',
  'reset:email:placeholder': '您的電子郵件地址',
  'reset:email:required': '郵箱不能為空',
  'reset:email:invalid': '電子郵件無效',
  'reset:resend': '重發',
  'reset:sendcode': '發送驗證碼',
  'reset:code:label': '驗證碼',
  'reset:code:placeholder': '驗證碼，可能是垃圾郵件',
  'reset:code:required': '驗證碼不能為空',
  'reset:newpassword:label': '新密碼',
  'reset:newpassword:placeholder': '密碼必須至少為6個字符',
  'reset:newpassword:required': '密碼不能為空',
  'reset:newpassword:invalid': '密碼必須至少為6個字符',
  'reset:password2:label': '確認密碼',
  'reset:password2:placeholder': '密碼必須至少為6個字符',
  'reset:password2:required': '確認密碼不能為空',
  'reset:password2:invalid': '輸入的密碼不匹配',
  'reset:reset': '重新設密碼',
  'reset:back': '回到',
  'reset:login': '登入',
  'agree:click': '透過點擊',
  'agree:text1': '，您同意我們的',
  'agree:text2': '和',
  'agree:terms': '服務條款',
  'agree:privacy': '隱私權政策',
  'app:loading': '載入中...',
  'app:welcome': '歡迎來到xBack',
  'success:error': '第三次授權錯誤',
  'success:success': '成功！',
  'success:tryAgain': '再試一次',
  'success:downloadTitle': '立即下載 xBack',
  'success:downloadText1': '受到我們會員的邀請，即可享有VIP獎勵。',
  'success:downloadText2':
    '您還可以邀請更多好友加入xBack社區，更多獎勵等您發現！',
  'success:coming': '即將推出',
  'success:redirected': '如果您沒有自動重定向',
  'copyright:copyright': '版權',
  'copyright:allRights': 'xBack Inc. 保留所有權利。',
  'download:title1': '下載x返回',
  'download:title2': 'xBack 網頁登入',
  'download:desc': '立即下載 xBack'
};
