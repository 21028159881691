export const es = {
  'noCookie:title': 'Cookies deshabilitadas',
  'noCookie:desc':
    'Su navegador ha desactivado las cookies. Asegúrese de que las cookies estén habilitadas y vuelva a intentarlo. ',

  'signup:google': 'Registrarse con Gmail',
  'signup:apple': 'Registrarse con Apple',
  'signup:microsoft': 'Registrarse con Microsoft',

  'login:google': 'Iniciar sesión con Gmail',
  'login:apple': 'Iniciar sesión con Apple',
  'login:microsoft': 'Iniciar sesión con Microsoft',
  'login:title': 'O inicia sesión con la cuenta xBack',
  'login:email:placeholder': 'Dirección de correo electrónico',
  'login:email:required': 'El correo electrónico no puede estar vacío',
  'login:email:invalid': 'El correo electrónico no es válido',
  'login:password:placeholder': 'Contraseña de xBack',
  'login:password:required': 'La contraseña no puede estar vacía',
  'login:remember': 'Recordar contraseña',
  'login:forget': '¿Olvidó su contraseña?',
  'login:signIn': 'Iniciar sesión',
  'login:cerateAccount': 'Crear una nueva cuenta xBack',
  'login:expired': 'El enlace ha caducado...',
  'login:access':
    'Si desea acceder a su cuenta xBack, acceda a los datos de su cuenta.',
  'signup:alert': 'Alerta',
  'signup:codetip': 'El código de invitación que ha especificado no es válido.',
  'signup:continue': 'Continuar',
  'signup:title': 'O cree una nueva cuenta xBack',
  'signup:resend': 'Vuelva a enviar',
  'signup:sendcode': 'Enviar código de verificación',
  'signup:code:placeholder':
    'Código de verificación, puede estar en el correo no deseado',
  'signup:code:required': 'El código de verificación no puede estar vacío',
  'signup:password:placeholder':
    'La contraseña debe tener al menos 6 caracteres',
  'signup:password:required': 'La contraseña no puede estar vacía',
  'signup:password:invalid': 'La contraseña debe tener al menos 6 caracteres',
  'signup:inviteCode:placeholder': 'Código de invitación (Opcional)',
  'signup:inviteCode:no': 'No tengo',
  'signup:inviteCode:has': 'Tengo un código de invitación',
  'signup:signup': 'Registrarse',
  'signup:hasAccount': ' ¿Ya tienes una cuenta? Inicie sesión ahora.',
  'reset:email:label': 'Correo electrónico',
  'reset:email:placeholder': 'Tu dirección de correo electrónico',
  'reset:email:required': 'El correo electrónico no puede estar vacío',
  'reset:email:invalid': 'El correo electrónico no es válido',
  'reset:resend': 'Vuelva a enviar',
  'reset:sendcode': 'Enviar código de verificación',
  'reset:code:label': 'Código de verificación',
  'reset:code:placeholder':
    'Código de verificación, puede estar en el correo no deseado',
  'reset:code:required': 'El código de verificación no puede estar vacío',
  'reset:newpassword:label': 'Nueva contraseña',
  'reset:newpassword:placeholder':
    'Las contraseñas deben tener al menos 6 caracteres',
  'reset:newpassword:required': 'La contraseña no puede estar vacía',
  'reset:newpassword:invalid':
    'Las contraseñas deben tener al menos 6 caracteres',
  'reset:password2:label': 'Confirmar contraseña',
  'reset:password2:placeholder':
    'La contraseña debe tener al menos 6 caracteres',
  'reset:password2:required':
    'La contraseña de confirmación no puede estar vacía',
  'reset:password2:invalid': 'Las contraseñas introducidas no coinciden',
  'reset:reset': 'Restablecer contraseña',
  'reset:back': 'Volver a ',
  'reset:login': 'Iniciar sesión',
  'agree:click': 'Al hacer clic en ',
  'agree:text1': 'acepta nuestras ',
  'agree:text2': ' y ',
  'agree:terms': 'Condiciones del servicio',
  'agree:privacy': 'Política de privacidad',
  'app:loading': 'Cargando...',
  'app:welcome': 'Bienvenido a xBack',
  'success:error': 'tercer error de autorización',
  'success:success': 'Éxito',
  'success:tryAgain': 'Vuelva a intentarlo',
  'success:downloadTitle': 'Descarga xBack ahora ',
  'success:downloadText1':
    ' para disfrutar de recompensas VIP, ya que has sido invitado por nuestro miembro.',
  'success:downloadText2':
    'También puedes invitar a más amigos a unirse a la comunidad xBack, ¡con más recompensas por descubrir!',
  'success:coming': 'Próximamente',
  'success:redirected': 'Si no eres redirigido automáticamente',
  'copyright:copyright': 'Derechos de autor',
  'copyright:allRights': ' xBack Inc. Todos los derechos reservados.',
  'download:title1': 'Descargar xBack',
  'download:title2': 'xBack inicio de sesión web',
  'download:desc': 'Descargar xBack ahora'
};
