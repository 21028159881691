import request from '@/utils/request';

export function login<T>(data: any) {
  return request<T>({
    method: 'post',
    url: '/alphant/api/base/V2Login',
    data: data
  });
}

export function signup<T>(data: any) {
  return request<T>({
    method: 'post',
    url: '/alphant/api/base/register/check',
    data: data
  });
}

export function sendEmailCode(data: any) {
  return request({
    url: '/alphant/api/base/register/email/sendCode',
    method: 'post',
    data: data
  });
}

export function resetPwd<T>(data: any) {
  return request<T>({
    method: 'post',
    url: '/alphant/api/base/clientUser/forgetPwd/check',
    data: data
  });
}

export function sendEmailCodeForReset(data: any) {
  return request({
    url: '/alphant/api/base/clientUser/forgetPwd/send',
    method: 'post',
    data: data
  });
}

export function verifyInviteCode(data: any) {
  return request({
    url: '/alphant/api/base/inviteCode/verify',
    method: 'post',
    data: data
  });
}

export function getIpInfo<T>() {
  return request<T>({
    method: 'get',
    url: '/getIpInfo/'
  });
}
