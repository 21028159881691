import React, { useEffect, useState } from 'react';
import type { FC, ReactNode } from 'react';
import FooterApple from '@/assets/images/footer-apple.svg';
import FooterAndriod from '@/assets/images/footer-andriod.svg';
import FooterGoogle from '@/assets/images/footer-google.svg';
import FooterOS from '@/assets/images/footer-os.svg';
import FooterWin from '@/assets/images/footer-win.svg';

import FooterDownLoad from '@/assets/images/footer-download.svg';

import IPhoneCode from '@/assets/images/iphone-code.png';
import AndriodCode from '@/assets/images/android-code.png';
import AndriodOfficialCode from '@/assets/images/android-official-code.png';

import './index.less';
import WeixinTip from '@/components/weixinTip';
import { Tooltip, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useSize } from 'ahooks';
import LogoComp from '@/components/logo';
import Copyright from '@/components/copyright';
import { getIpInfo } from '@/api/login';
import { useAppDispatch, useAppSelector } from '@/store';
import { updateCountryCode } from '@/store/module/account';
import { FormattedMessage, useIntl } from 'react-intl';

interface IProps {
  children?: ReactNode;
}
const Download: FC<IProps> = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { countryCode } = useAppSelector((state) => state.account);
  const [visiable, setVisibale] = useState(false);
  const bodySize = useSize(document.querySelector('body'));
  const [loading, setLoading] = useState(false);

  const dataList = [
    {
      icon: FooterOS,
      text: 'macOS',
      canDownload: true,
      link: 'https://download.xback.io/download/macos/latest',
      code: undefined
    },
    {
      icon: FooterWin,
      text: 'Windows',
      canDownload: true,
      link: 'https://download.xback.io/download/windows/latest',
      code: undefined
    },
    {
      icon: FooterApple,
      text: 'iPhone',
      canDownload: true,
      link: 'https://download.xback.io/download/ios/latest',
      code: [
        {
          icon: IPhoneCode,
          label: ''
        }
      ]
    },
    {
      icon: FooterGoogle,
      text: 'Google Play',
      canDownload: true,
      link: `https://download.xback.io/download/android/latest`,
      code: [
        {
          icon: AndriodCode,
          label: ''
        }
      ]
    }
  ];
  if (countryCode !== 'IR') {
    dataList.splice(3, 0, {
      icon: FooterAndriod,
      text: 'Official APK',
      canDownload: true,
      link: `https://download.xback.io/download/android_official/latest`,
      code: [
        {
          icon: AndriodOfficialCode,
          label: ''
        }
      ]
    });
  }

  const fetchIpInfo = async () => {
    setLoading(true);
    const data: any = await getIpInfo();
    dispatch(updateCountryCode(data?.country_code ?? ''));
    setLoading(false);
  };

  useEffect(() => {
    fetchIpInfo();
    document.title = intl.formatMessage({ id: 'download:title1' });
    return () => {
      document.title = intl.formatMessage({ id: 'download:title2' });
    };
  }, []);

  const loadingIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: '#2ba245' }} spin />
  );
  return (
    <Spin indicator={loadingIcon} spinning={loading}>
      <div className="download-container" style={{ display: 'block' }}>
        <LogoComp />
        <div className="content-container">
          <div className="download-desc">
            <span style={{ fontWeight: 700 }}>
              <FormattedMessage id="download:desc" />
            </span>
          </div>
          <div className="download-download-container">
            {dataList.map((item: any, idx: number) => (
              <div
                className="download-item"
                key={idx}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  const ua: any = window.navigator.userAgent.toLowerCase();
                  if (
                    ua.match(/MicroMessenger/i) == 'micromessenger' ||
                    ua.match(/WeiBo/i) == 'weibo' ||
                    ua.match(/QQ/i) == 'qq'
                  ) {
                    setVisibale(true);
                  } else {
                    window.location.href = item.link;
                  }
                }}
              >
                <div className="download-icon">
                  <img src={item.icon} />
                </div>
                <div className="download-text">{item.text}</div>
                <div className="download-link">
                  <Tooltip
                    color="#51ac36"
                    placement="bottom"
                    title={
                      !!item.code &&
                      item.canDownload &&
                      (bodySize?.width ?? 0) > 640 ? (
                        <div className="landing-code-container">
                          {item.code.map((item: any, idx: number) => {
                            return (
                              <div className="landing-code-item" key={idx}>
                                <div>
                                  <img src={item.icon} alt="" />
                                </div>
                                <div>{item.label}</div>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        ''
                      )
                    }
                    arrowPointAtCenter
                    key={item.text}
                  >
                    {item.canDownload ? (
                      <img src={FooterDownLoad} />
                    ) : (
                      <div className="download-link-text">
                        <FormattedMessage id="success:coming" />
                      </div>
                    )}
                  </Tooltip>
                </div>
              </div>
            ))}
          </div>
        </div>

        <Copyright />

        <WeixinTip visiable={visiable} setVisibale={setVisibale} />
      </div>
    </Spin>
  );
};

export default Download;
