import { en } from './lang/en';
import { zh } from './lang/zh';
import { HK_MO_TW } from './lang/HK_MO_TW';
import { ar } from './lang/ar';
import { de } from './lang/de';
import { es } from './lang/es';
import { fr } from './lang/fr';
import { id } from './lang/id';
import { ja } from './lang/ja';
import { ko } from './lang/ko';
import { ms } from './lang/ms';
import { pt } from './lang/pt';
import { ru } from './lang/ru';
import { th } from './lang/th';
import { vi } from './lang/vi';

export const langKey = [
  {
    key: 'zh-TW',
    val: HK_MO_TW
  },
  {
    key: 'zh-HK',
    val: HK_MO_TW
  },
  {
    key: 'zh-MO',
    val: HK_MO_TW
  },
  {
    key: 'zh-CN',
    val: zh
  },
  {
    key: 'zh',
    val: zh
  },
  {
    key: 'en',
    val: en
  },
  {
    key: 'ar',
    val: ar
  },
  {
    key: 'de',
    val: de
  },
  {
    key: 'es',
    val: es
  },
  {
    key: 'fr',
    val: fr
  },
  {
    key: 'id',
    val: id
  },
  {
    key: 'ja',
    val: ja
  },
  {
    key: 'ko',
    val: ko
  },
  {
    key: 'ms',
    val: ms
  },
  {
    key: 'pt',
    val: pt
  },
  {
    key: 'ru',
    val: ru
  },
  {
    key: 'th',
    val: th
  },
  {
    key: 'vi',
    val: vi
  }
];
export const chooseLocale = (val) => {
  const lang = langKey.find((item) => item.key === val);
  return lang?.val || en;
};
