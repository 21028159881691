export const ms = {
  'noCookie:title': 'Kuki dilumpuhkan',
  'noCookie:desc':
    'Penyemak imbas anda telah melumpuhkan kuki. Sila pastikan kuki didayakan dan cuba lagi.',

  'signup:google': 'Daftar dengan Gmail',
  'signup:apple': 'Daftar dengan Apple',
  'signup:microsoft': 'Daftar dengan Microsoft',

  'login:google': 'Log masuk dengan Gmail',
  'login:apple': 'Log masuk dengan Apple',
  'login:microsoft': 'Log masuk dengan Microsoft',
  'login:title': 'Atau log masuk dengan akaun xBack',
  'login:email:placeholder': 'Alamat emel',
  'login:email:required': 'E-mel tidak boleh kosong',
  'login:email:invalid': 'Email tidak sah',
  'login:password:placeholder': 'Kata laluan xBack',
  'login:password:required': 'Kata laluan tidak boleh kosong',
  'login:remember': 'Ingat saya',
  'login:forget': 'lupa kata laluan?',
  'login:signIn': 'Log masuk',
  'login:cerateAccount': 'Buat akaun xBack baharu',
  'login:expired': 'Pautan telah tamat tempoh...',
  'login:access':
    'Sila berikan akses kepada butiran akaun anda jika anda ingin log masuk ke akaun xBack anda.',
  'signup:alert': 'Amaran',
  'signup:codetip': 'Kod jemputan yang anda nyatakan adalah tidak sah.',
  'signup:continue': 'teruskan',
  'signup:title': 'Atau buat akaun xBack baharu',
  'signup:resend': 'Hantar semula',
  'signup:sendcode': 'Hantar kod pengesahan',
  'signup:code:placeholder': 'Kod pengesahan, ia mungkin dalam mel sampah',
  'signup:code:required': 'Kod Pengesahan tidak boleh kosong',
  'signup:password:placeholder':
    'Kata laluan mestilah sekurang-kurangnya 6 aksara',
  'signup:password:required': 'Kata laluan tidak boleh kosong',
  'signup:password:invalid': 'Kata laluan mestilah sekurang-kurangnya 6 aksara',
  'signup:inviteCode:placeholder': 'Kod jemputan (Pilihan)',
  'signup:inviteCode:no': 'Saya tidak mempunyai satu',
  'signup:inviteCode:has': 'Saya mempunyai Kod Jemputan',
  'signup:signup': 'daftar',
  'signup:hasAccount': ' Sudah mempunyai akaun? Log masuk sekarang.',
  'reset:email:label': 'E-mel',
  'reset:email:placeholder': 'alamat e-mel anda',
  'reset:email:required': 'E-mel tidak boleh kosong',
  'reset:email:invalid': 'Email tidak sah',
  'reset:resend': 'Hantar semula',
  'reset:sendcode': 'Hantar kod pengesahan',
  'reset:code:label': 'Kod pengesahan',
  'reset:code:placeholder': 'Kod pengesahan, ia mungkin dalam mel sampah',
  'reset:code:required': 'Kod Pengesahan tidak boleh kosong',
  'reset:newpassword:label': 'Kata laluan baharu',
  'reset:newpassword:placeholder':
    'Kata laluan mestilah sekurang-kurangnya 6 aksara',
  'reset:newpassword:required': 'Kata laluan tidak boleh kosong',
  'reset:newpassword:invalid':
    'Kata laluan mestilah sekurang-kurangnya 6 aksara',
  'reset:password2:label': 'Sahkan kata laluan',
  'reset:password2:placeholder':
    'Kata laluan mestilah sekurang-kurangnya 6 aksara',
  'reset:password2:required': 'Sahkan kata laluan tidak boleh kosong',
  'reset:password2:invalid': 'Kata laluan yang dimasukkan tidak sepadan',
  'reset:reset': 'Menetapkan semula kata laluan',
  'reset:back': 'Kembali kepada ',
  'reset:login': 'Log masuk',
  'agree:click': 'Dengan mengklik ',
  'agree:text1': ', anda bersetuju dengan kami ',
  'agree:text2': ' dan ',
  'agree:terms': 'Syarat Perkhidmatan',
  'agree:privacy': 'Dasar Privasi',
  'app:loading': 'Memuatkan...',
  'app:welcome': 'Selamat datang ke xBack',
  'success:error': 'ralat kebenaran ketiga',
  'success:success': 'Berjaya!',
  'success:tryAgain': 'Cuba lagi',
  'success:downloadTitle': 'Muat turun xBack sekarang ',
  'success:downloadText1':
    ' untuk menikmati ganjaran VIP kerana anda telah dijemput oleh ahli kami.',
  'success:downloadText2':
    'Anda juga boleh menjemput lebih ramai rakan untuk menyertai komuniti xBack, dengan lebih banyak ganjaran untuk ditemui!',
  'success:coming': 'Akan datang',
  'success:redirected': 'Jika anda tidak diubah hala secara automatik',
  'copyright:copyright': 'hak cipta',
  'copyright:allRights': ' xBack Inc. Hak cipta terpelihara.',
  'download:title1': 'Muat turun xBack',
  'download:title2': 'log masuk web xBack',
  'download:desc': 'Muat turun xBack sekarang'
};
