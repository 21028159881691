export const ru = {
  'noCookie:title': 'Файлы cookie отключены',
  'noCookie:desc':
    'Ваш браузер отключил файлы cookie. Пожалуйста, убедитесь, что файлы cookie включены, и повторите попытку.',

  'signup:google': 'Регистрация через Gmail',
  'signup:apple': 'Регистрация через Apple',
  'signup:microsoft': 'Регистрация через Microsoft',

  'login:google': 'Вход через Gmail',
  'login:apple': 'Продолжить с Apple',
  'login:microsoft': 'Вход через Microsoft',
  'login:title': 'Или войдите в систему с помощью учетной записи xBack',
  'login:email:placeholder': 'Адрес электронной почты',
  'login:email:required': 'Электронная почта не может быть пустой',
  'login:email:invalid': 'Email недействителен',
  'login:password:placeholder': 'Пароль xBack',
  'login:password:required': 'Пароль не может быть пустым',
  'login:remember': 'Запомнить меня',
  'login:forget': 'Забыли пароль?',
  'login:signIn': 'Войти',
  'login:cerateAccount': 'Создать новую учетную запись xBack',
  'login:expired': 'Срок действия ссылки истек...',
  'login:access':
    'Пожалуйста, предоставьте доступ к данным вашей учетной записи, если вы хотите войти в свой аккаунт xBack.',
  'signup:alert': 'Оповещение',
  'signup:codetip': 'Указанный вами код приглашения недействителен.',
  'signup:continue': 'Продолжить',
  'signup:title': 'Или создайте новую учетную запись xBack',
  'signup:resend': 'Отправить повторно',
  'signup:sendcode': 'Отправить проверочный код',
  'signup:code:placeholder':
    'Код верификации, возможно, находится в нежелательной почте',
  'signup:code:required': 'Код верификации не может быть пустым',
  'signup:password:placeholder':
    'Пароль должен состоять не менее чем из 6 символов',
  'signup:password:required': 'Пароль не может быть пустым',
  'signup:password:invalid':
    'Пароль должен состоять не менее чем из 6 символов',
  'signup:inviteCode:placeholder': 'Код приглашения (необязательно)',
  'signup:inviteCode:no': 'У меня его нет',
  'signup:inviteCode:has': 'У меня есть код приглашения',
  'signup:signup': 'Зарегистрироваться',
  'signup:hasAccount': ' У вас уже есть аккаунт? Войдите сейчас.',
  'reset:email:label': 'Электронная почта',
  'reset:email:placeholder': 'Ваш адрес электронной почты',
  'reset:email:required': 'Email не может быть пустым',
  'reset:email:invalid': 'Email недействителен',
  'reset:resend': 'Отправить повторно',
  'reset:sendcode': 'Отправить код проверки',
  'reset:code:label': 'Код верификации',
  'reset:code:placeholder':
    'Код верификации, возможно, он находится в нежелательной почте',
  'reset:code:required': 'Код проверки не может быть пустым',
  'reset:newpassword:label': 'Новый пароль',
  'reset:newpassword:placeholder':
    'Пароль должен состоять не менее чем из 6 символов',
  'reset:newpassword:required': 'Пароль не может быть пустым',
  'reset:newpassword:invalid':
    'Пароль должен состоять не менее чем из 6 символов',
  'reset:password2:label': 'Подтверждение пароля',
  'reset:password2:placeholder':
    'Пароль должен состоять не менее чем из 6 символов',
  'reset:password2:required': 'Подтверждающий пароль не может быть пустым',
  'reset:password2:invalid': 'Введенные пароли не совпадают',
  'reset:reset': 'Сбросить пароль',
  'reset:back': 'Вернуться к ',
  'reset:login': 'Войти',
  'agree:click': 'Нажимая ',
  'agree:text1': 'вы соглашаетесь с нашими ',
  'agree:text2': ' и ',
  'agree:terms': 'Условиями предоставления услуг',
  'agree:privacy': 'Политика конфиденциальности',
  'app:loading': 'Загрузка...',
  'app:welcome': 'Добро пожаловать в xBack',
  'success:error': 'третья ошибка авторизации',
  'success:success': 'Не удалось!',
  'success:tryAgain': 'Попробуйте еще раз',
  'success:downloadTitle': 'Скачайте xBack прямо сейчас ',
  'success:downloadText1':
    ' чтобы получить VIP-премии по приглашению нашего участника.',
  'success:downloadText2':
    'Вы также можете пригласить больше друзей присоединиться к сообществу xBack и получить еще больше наград!',
  'success:coming': 'Скоро будет',
  'success:redirected': 'Если вы не перенаправляетесь автоматически',
  'copyright:copyright': 'Copyright',
  'copyright:allRights': ' xBack Inc. Все права защищены.',
  'download:title1': 'Скачать xBack',
  'download:title2': 'xBack веб-логин',
  'download:desc': 'Скачать xBack сейчас'
};
