import { ACCOUNT_INFO, SCHEME } from './constant';

interface IAccountInfo {
  username: string;
  password: string;
}

export const getAccountInfo = (key: keyof IAccountInfo) => {
  const config = localStorage.getItem(ACCOUNT_INFO);

  return config ? JSON.parse(config)[key] : '';
};

export const setAccountInfo = (key: keyof IAccountInfo, value: string) => {
  let config: IAccountInfo;
  const accountInfo = localStorage.getItem(ACCOUNT_INFO);
  if (accountInfo) {
    config = JSON.parse(accountInfo);
  } else {
    config = {} as IAccountInfo;
  }
  config[key] = value;
  localStorage.setItem(ACCOUNT_INFO, JSON.stringify(config));
};

export function clearAccountInfo() {
  localStorage.removeItem(ACCOUNT_INFO);
}

export function getScheme() {
  return localStorage.getItem(SCHEME) ?? '';
}

export function setScheme(scheme: string) {
  localStorage.setItem(SCHEME, scheme);
}

export function clearScheme() {
  localStorage.removeItem(SCHEME);
}

export function getLanguage() {
  // return localStorage.getItem('LANGUAGE') ?? '';
  return '';
}

export function setLanguage(language?: string) {
  // localStorage.setItem('LANGUAGE', language ?? '');
}

export function clearLanguage() {
  localStorage.removeItem('LANGUAGE');
}
