export const fr = {
  'noCookie:title': 'Cookies désactivés',
  'noCookie:desc':
    'Votre navigateur a désactivé les cookies. Veuillez vous assurer que les cookies sont activés et réessayer. ',

  'signup:google': "S'inscrire avec Gmail",
  'signup:apple': "S'inscrire avec Apple",
  'signup:microsoft': "S'inscrire avec Microsoft",

  'login:google': 'Se connecter avec Gmail',
  'login:apple': 'Se connecter avec Apple',
  'login:microsoft': 'Se connecter avec Microsoft',
  'login:title': 'Ou se connecter avec le compte xBack',
  'login:email:placeholder': 'Adresse e-mail',
  'login:email:required': "L'adresse e-mail ne peut pas être vide",
  'login:email:invalid': "L'adresse e-mail n'est pas valide",
  'login:password:placeholder': 'Mot de passe xBack',
  'login:password:required': 'Le mot de passe ne peut pas être vide',
  'login:remember': 'Se souvenir de moi',
  'login:forget': 'Oublié le mot de passe ?',
  'login:signIn': "S'identifier",
  'login:cerateAccount': 'Créer un nouveau compte xBack',
  'login:expired': 'Le lien a expiré...',
  'login:access':
    "Veuillez autoriser l'accès aux détails de votre compte si vous souhaitez vous connecter à votre compte xBack.",
  'signup:alert': 'Alerte',
  'signup:codetip':
    "Le code d'invitation que vous avez indiqué n'est pas valide.",
  'signup:continue': 'Continuer',
  'signup:title': 'Ou créer un nouveau compte xBack',
  'signup:resend': 'Renvoyer',
  'signup:sendcode': 'Envoyer le code de vérification',
  'signup:code:placeholder':
    "Code de vérification, il se peut qu'il soit dans le courrier indésirable.",
  'signup:code:required': 'Le code de vérification ne peut pas être vide',
  'signup:password:placeholder':
    'Le mot de passe doit comporter au moins 6 caractères',
  'signup:password:required': 'Le mot de passe ne peut pas être vide',
  'signup:password:invalid':
    'Le mot de passe doit comporter au moins 6 caractères',
  'signup:inviteCode:placeholder': "Code d'invitation (optionnel)",
  'signup:inviteCode:no': "Je n'en ai pas",
  'signup:inviteCode:has': "J'ai un code d'invitation",
  'signup:signup': "S'inscrire",
  'signup:hasAccount': " Vous avez déjà un compte ? S'identifier maintenant.",
  'reset:email:label': 'Courriel',
  'reset:email:placeholder': 'Votre adresse e-mail',
  'reset:email:required': "L'adresse électronique ne peut pas être vide",
  'reset:email:invalid': "L'email n'est pas valide",
  'reset:resend': 'Renvoyer',
  'reset:sendcode': 'Envoyer le code de vérification',
  'reset:code:label': 'Code de vérification',
  'reset:code:placeholder':
    'Code de vérification, il peut être dans le courrier indésirable.',
  'reset:code:required': 'Le code de vérification ne peut pas être vide',
  'reset:newpassword:label': 'Nouveau mot de passe',
  'reset:newpassword:placeholder':
    'Les mots de passe doivent comporter au moins 6 caractères',
  'reset:newpassword:required': 'Le mot de passe ne peut pas être vide',
  'reset:newpassword:invalid':
    'Les mots de passe doivent comporter au moins 6 caractères',
  'reset:password2:label': 'Confirmer le mot de passe',
  'reset:password2:placeholder':
    'Les mots de passe doivent comporter au moins 6 caractères',
  'reset:password2:required':
    'Le mot de passe de confirmation ne peut pas être vide',
  'reset:password2:invalid': 'Les mots de passe saisis ne correspondent pas',
  'reset:reset': 'Réinitialiser le mot de passe',
  'reset:back': 'Retourner à ',
  'reset:login': "S'identifier",
  'agree:click': 'En cliquant sur ',
  'agree:text1': 'vous acceptez nos ',
  'agree:text2': ' et ',
  'agree:terms': "Conditions d'utilisation",
  'agree:privacy': 'Politique de confidentialité',
  'app:loading': 'Chargement...',
  'app:welcome': 'Bienvenue à xBack',
  'success:error': 'Erreur de troisième autorisation',
  'success:success': 'Réussir !',
  'success:tryAgain': 'Réessayer',
  'success:downloadTitle': 'Téléchargez xBack maintenant ',
  'success:downloadText1':
    ' pour profiter des récompenses VIP, car vous avez été invité par notre membre.',
  'success:downloadText2':
    "Vous pouvez également inviter d'autres amis à rejoindre la communauté xBack, avec plus de récompenses à découvrir !",
  'success:coming': 'Bientôt disponible',
  'success:redirected': "Si vous n'êtes pas redirigé automatiquement",
  'copyright:copyright': "Droits d'auteur",
  'copyright:allRights': ' xBack Inc. Tous droits réservés.',
  'download:title1': 'Télécharger xBack',
  'download:title2': 'xBack web login',
  'download:desc': 'Télécharger xBack maintenant'
};
