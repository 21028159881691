import Download from '@/views/download';
import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import type { RouteObject } from 'react-router-dom';

const SignUp = lazy(() => import('@/views/signup'));
const SignIn = lazy(() => import('@/views/signin'));
const Reset = lazy(() => import('@/views/reset'));
const Success = lazy(() => import('@/views/success'));
const routes: RouteObject[] = [
  {
    path: '/',
    element: <Navigate to={'/signin'} />
  },
  {
    path: '/signin',
    element: <SignIn />
  },
  {
    path: '/signup',
    element: <SignUp />
  },
  {
    path: '/reset',
    element: <Reset />
  },
  {
    path: '/auth/success',
    element: <Success />
  },
  {
    path: '/download',
    element: <Download />
  }
  // {
  //   path: '*',
  //   element: <Navigate to={'/signin'} />
  // }
];
export default routes;
