import React from 'react';
import type { FC, ReactNode } from 'react';
import { CopyrightOutlined } from '@ant-design/icons';
import './index.less';
import { FormattedMessage } from 'react-intl';

interface IProps {
  children?: ReactNode;
}
const Copyright: FC<IProps> = () => {
  return (
    <div className="copyright">
      <FormattedMessage id="copyright:copyright" />
      <CopyrightOutlined style={{ margin: '0 5px' }} />
      {new Date().getFullYear()}
      <FormattedMessage id="copyright:allRights" />
    </div>
  );
};

export default Copyright;
