export const vi = {
  'noCookie:title': 'Cookie bị vô hiệu hóa',
  'noCookie:desc':
    'Trình duyệt của bạn đã tắt cookie. Vui lòng đảm bảo rằng cookie đã được bật và thử lại. ',

  'signup:google': 'Đăng ký bằng Gmail',
  'signup:apple': 'Đăng ký bằng Apple',
  'signup:microsoft': 'Đăng ký bằng Microsoft',

  'login:google': 'Đăng nhập bằng Gmail',
  'login:apple': 'Đăng nhập bằng Apple',
  'login:microsoft': 'Đăng nhập bằng Microsoft',
  'login:title': 'Hoặc đăng nhập bằng tài khoản xBack',
  'login:email:placeholder': 'Địa chỉ email',
  'login:email:required': 'Email không được để trống',
  'login:email:invalid': 'Email không hợp lệ',
  'login:password:placeholder': 'mật khẩu xBack',
  'login:password:required': 'Mật khẩu không được để trống',
  'login:remember': 'nhớ tôi',
  'login:forget': 'Quên mật khẩu?',
  'login:signIn': 'Đăng nhập',
  'login:cerateAccount': 'Tạo tài khoản xBack mới',
  'login:expired': 'Liên kết đã hết hạn...',
  'login:access':
    'Vui lòng cấp quyền truy cập vào chi tiết tài khoản của bạn nếu bạn muốn đăng nhập vào tài khoản xBack của mình.',
  'signup:alert': 'Báo động',
  'signup:codetip': 'Mã lời mời bạn chỉ định không hợp lệ.',
  'signup:continue': 'Tiếp tục',
  'signup:title': 'Hoặc tạo tài khoản xBack mới',
  'signup:resend': 'Gửi lại',
  'signup:sendcode': 'Gửi mã xác minh',
  'signup:code:placeholder': 'Mã xác minh, có thể nằm trong thư rác',
  'signup:code:required': 'Mã xác minh không được để trống',
  'signup:password:placeholder': 'Mật khẩu cần dài ít nhất 6 ký tự',
  'signup:password:required': 'Mật khẩu không được để trống',
  'signup:password:invalid': 'Mật khẩu cần dài ít nhất 6 ký tự',
  'signup:inviteCode:placeholder': 'Mã mời (Tùy chọn)',
  'signup:inviteCode:no': 'Tôi không có',
  'signup:inviteCode:has': 'Tôi có Mã mời',
  'signup:signup': 'Đăng ký',
  'signup:hasAccount': ' Bạn co săn san để tạo một tai khoản? Đăng nhập ngay.',
  'reset:email:label': 'E-mail',
  'reset:email:placeholder': 'địa chỉ email của bạn',
  'reset:email:required': 'Email không được để trống',
  'reset:email:invalid': 'Email không hợp lệ',
  'reset:resend': 'Gửi lại',
  'reset:sendcode': 'Gửi mã xác minh',
  'reset:code:label': 'Mã xác nhận',
  'reset:code:placeholder': 'Mã xác minh, có thể nằm trong thư rác',
  'reset:code:required': 'Mã xác minh không được để trống',
  'reset:newpassword:label': 'Mật khẩu mới',
  'reset:newpassword:placeholder': 'Mật khẩu phải có ít nhất 6 ký tự',
  'reset:newpassword:required': 'Mật khẩu không được để trống',
  'reset:newpassword:invalid': 'Mật khẩu phải có ít nhất 6 ký tự',
  'reset:password2:label': 'Xác nhận mật khẩu',
  'reset:password2:placeholder': 'Mật khẩu phải có ít nhất 6 ký tự',
  'reset:password2:required': 'Xác nhận mật khẩu không được để trống',
  'reset:password2:invalid': 'Mật khẩu đã nhập không khớp',
  'reset:reset': 'Đặt lại mật khẩu',
  'reset:back': 'Quay lại ',
  'reset:login': 'Đăng nhập',
  'agree:click': 'Bằng cách nhấp chuột ',
  'agree:text1': ', bạn đồng ý với chúng tôi ',
  'agree:text2': ' Và ',
  'agree:terms': 'Điều khoản dịch vụ',
  'agree:privacy': 'Chính sách bảo mật',
  'app:loading': 'Đang tải...',
  'app:welcome': 'Chào mừng đến với xBack',
  'success:error': 'lỗi ủy quyền thứ ba',
  'success:success': 'Thành công!',
  'success:tryAgain': 'Thử lại',
  'success:downloadTitle': 'Tải xuống xBack ngay bây giờ ',
  'success:downloadText1':
    ' để tận hưởng phần thưởng VIP như bạn đã được thành viên của chúng tôi mời.',
  'success:downloadText2':
    'Bạn cũng có thể mời thêm bạn bè tham gia cộng đồng xBack, với nhiều phần thưởng hơn để khám phá!',
  'success:coming': 'Sắp ra mắt',
  'success:redirected': 'Nếu bạn không được chuyển hướng tự động',
  'copyright:copyright': 'Bản quyền',
  'copyright:allRights': ' xBack Inc. Mọi quyền được bảo lưu.',
  'download:title1': 'Tải xuống xBack',
  'download:title2': 'đăng nhập web xBack',
  'download:desc': 'Tải xuống xBack ngay bây giờ'
};
