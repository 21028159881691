export const pt = {
  'noCookie:title': 'Cookies desativados',
  'noCookie:desc':
    'O seu navegador desativou os cookies. Certifique-se de que os cookies estão ativados e tente novamente.',

  'signup:google': 'Inscrever-se com Gmail',
  'signup:apple': 'Inscrever-se com Apple',
  'signup:microsoft': 'Inscrever-se com Microsoft',

  'login:google': 'Entrar com Gmail',
  'login:apple': 'Entrar com Apple',
  'login:microsoft': 'Entrar com Microsoft',
  'login:title': 'Ou iniciar sessão com a conta xBack',
  'login:email:placeholder': 'Endereço de correio eletrónico',
  'login:email:required': 'O e-mail não pode estar vazio',
  'login:email:invalid': 'O e-mail é inválido',
  'login:password:placeholder': 'Palavra-passe xBack',
  'login:password:required': 'A palavra-passe não pode estar vazia',
  'login:remember': 'Lembrar-me',
  'login:forget': 'Esqueceu-se da palavra-passe?',
  'login:signIn': 'Iniciar sessão',
  'login:cerateAccount': 'Criar uma nova conta xBack',
  'login:expired': 'A ligação expirou...',
  'login:access':
    'Conceda acesso aos detalhes da sua conta se pretender iniciar sessão na sua conta xBack.',
  'signup:alert': 'Alerta',
  'signup:codetip': 'O código de convite que especificou é inválido.',
  'signup:continue': 'Continuar',
  'signup:title': 'Ou criar uma nova conta xBack',
  'signup:resend': 'Reenviar',
  'signup:sendcode': 'Enviar código de verificação',
  'signup:code:placeholder':
    'Código de verificação, pode estar no correio não solicitado',
  'signup:code:required': 'O código de verificação não pode estar vazio',
  'signup:password:placeholder':
    'A palavra-passe tem de ter pelo menos 6 caracteres',
  'signup:password:required': 'A palavra-passe não pode estar vazia',
  'signup:password:invalid':
    'A palavra-passe tem de ter pelo menos 6 caracteres',
  'signup:inviteCode:placeholder': 'Código de convite (opcional)',
  'signup:inviteCode:no': 'Não tenho um',
  'signup:inviteCode:has': 'Tenho um código de convite',
  'signup:signup': 'Registar',
  'signup:hasAccount': ' Já tem uma conta? Iniciar sessão agora.',
  'reset:email:label': 'Email',
  'reset:email:placeholder': 'O seu endereço de correio eletrónico',
  'reset:email:required': 'O e-mail não pode estar vazio',
  'reset:email:invalid': 'O e-mail é inválido',
  'reset:resend': 'Reenviar',
  'reset:sendcode': 'Enviar código de verificação',
  'reset:code:label': 'Código de verificação',
  'reset:code:placeholder':
    'Código de verificação, pode estar no lixo eletrónico',
  'reset:code:required': 'O código de verificação não pode estar vazio',
  'reset:newpassword:label': 'Nova palavra-passe',
  'reset:newpassword:placeholder':
    'As palavras-passe devem ter pelo menos 6 caracteres',
  'reset:newpassword:required': 'A palavra-passe não pode estar vazia',
  'reset:newpassword:invalid':
    'A palavra-passe tem de ter pelo menos 6 caracteres',
  'reset:password2:label': 'Confirmar palavra-passe',
  'reset:password2:placeholder':
    'A palavra-passe deve ter pelo menos 6 caracteres',
  'reset:password2:required':
    'A palavra-passe de confirmação não pode estar vazia',
  'reset:password2:invalid': 'As palavras-passe introduzidas não correspondem',
  'reset:reset': 'Repor a palavra-passe',
  'reset:back': 'Voltar para ',
  'reset:login': 'Iniciar sessão',
  'agree:click': 'Ao clicar em ',
  'agree:text1': ', concorda com os nossos ',
  'agree:text2': ' e ',
  'agree:terms': 'Termos de serviço',
  'agree:privacy': 'Política de privacidade',
  'app:loading': 'Carregando...',
  'app:welcome': 'Bem-vindo ao xBack',
  'success:error': 'erro de terceira autorização',
  'success:success': 'Sucesso!',
  'success:tryAgain': 'Tente novamente',
  'success:downloadTitle': 'Descarregar o xBack agora ',
  'success:downloadText1':
    ' para usufruir da recompensa VIP, uma vez que foi convidado pelo nosso membro.',
  'success:downloadText2':
    'Também pode convidar mais amigos para se juntarem à comunidade xBack, com mais recompensas para descobrir!',
  'success:coming': 'Em breve',
  'success:redirected': 'Se não for redireccionado automaticamente',
  'copyright:copyright': 'Direitos de autor',
  'copyright:allRights': ' xBack Inc. Todos os direitos reservados.',
  'download:title1': 'Descarregar o xBack',
  'download:title2': 'xBack web login',
  'download:desc': 'Descarregar o xBack agora'
};
