import React, { ReactNode } from 'react';
import { IntlProvider } from 'react-intl';
import { chooseLocale, langKey } from '@/locale/index';
import { getLanguage, setLanguage } from '@/utils/localStorage';
import { useAppDispatch, useAppSelector } from '@/store';
import { updateLanguage } from '@/store/module/account';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { decrypt } from '@/utils/aes';

interface IProps {
  children?: ReactNode;
}

const Intl = ({ children }: IProps) => {
  const dispatch = useAppDispatch();

  const { code, error } = queryString.parse(useLocation().search);
  if (code) {
    let data: any = {};
    try {
      data = JSON.parse(decrypt(decodeURIComponent(code as string)) || '{}');
    } catch (error) {
      console.log(error);
    }

    const locale = data?.language?.replace('_', '-');
    if (locale) {
      dispatch(updateLanguage(locale));
    }
  }

  const { language } = useAppSelector((state) => state.account);

  const localeTemp = language || getLanguage() || navigator.language; // 获取 locale

  const locale =
    langKey
      .map((item) => item.key)
      .find((i) => i === localeTemp || i === localeTemp.split('-')[0]) || 'en';

  if (!language) {
    dispatch(updateLanguage(locale));
  }
  if (!getLanguage()) {
    setLanguage(locale);
  }

  if (locale === 'ar') {
    document.body.dir = 'rtl';
  } else {
    document.body.dir = 'ltr';
  }

  // 包裹子组件 让子组件共享react-intl的api 实现多语言
  return (
    <IntlProvider
      key={locale}
      locale={locale}
      defaultLocale={locale}
      messages={chooseLocale(locale)}
    >
      {children}
    </IntlProvider>
  );
};

export default Intl;
