import React, { FC, ReactNode } from 'react';

import LiveWenxin from '@/assets/images/live_weixin.png';
import './index.less';

interface IProps {
  children?: ReactNode;
  visiable: boolean;
  setVisibale: (visiable: boolean) => void;
}
const Success: FC<IProps> = ({ visiable, setVisibale }) => {
  return visiable ? (
    <div
      className="weixin-tip"
      onClick={() => {
        setVisibale(false);
      }}
    >
      <p>
        <img src={LiveWenxin} alt="微信打开" />
      </p>
    </div>
  ) : (
    <span></span>
  );
};

export default Success;
