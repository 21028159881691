export const zh = {
  'noCookie:title': 'Cookie 已停用',
  'noCookie:desc': '您的浏览器停用了 Cookie。请务必启用 Cookie，然后重试。',

  'signup:google': '使用 Gmail 注册',
  'signup:apple': '使用 Apple 注册',
  'signup:microsoft': '使用 Microsoft 注册',

  'login:google': '使用 Gmail 登录',
  'login:apple': '使用 Apple 登录',
  'login:microsoft': '使用 Microsoft 登录',
  'login:title': '或使用 xBack 帐户登录',
  'login:email:placeholder': '电子邮件地址',
  'login:email:required': '电子邮件不能为空',
  'login:email:invalid': '电子邮件无效',
  'login:password:placeholder': '密码',
  'login:password:required': '密码不能为空',
  'login:remember': '记住我的密码',
  'login:forget': '忘记密码？',
  'login:signIn': '登录',
  'login:cerateAccount': '创建新的 xBack 帐户',
  'login:expired': '链接已过期...',
  'login:access': '如果您希望登录您的 xBack 帐户，请授权访问您的帐户详情。',
  'signup:alert': '提醒',
  'signup:codetip': '您指定的邀请码无效。',
  'signup:continue': '继续',
  'signup:title': '或创建新的 xBack 帐户',
  'signup:resend': '重新发送',
  'signup:sendcode': '发送验证码',
  'signup:code:placeholder': '验证码，可能在垃圾邮件中',
  'signup:code:required': '验证码不能为空',
  'signup:password:placeholder': '密码必须至少为6个字符',
  'signup:password:required': '密码不能为空',
  'signup:password:invalid': '密码必须至少包含6个字符',
  'signup:inviteCode:placeholder': '邀请码（可选）',
  'signup:inviteCode:no': '我没有',
  'signup:inviteCode:has': '我有邀请码',
  'signup:signup': '注册',
  'signup:hasAccount': '已有帐户？立即登录。',
  'reset:email:label': '电子邮件',
  'reset:email:placeholder': '您的电子邮件地址',
  'reset:email:required': '电子邮件不能为空',
  'reset:email:invalid': '电子邮件无效',
  'reset:resend': '重新发送',
  'reset:sendcode': '发送验证码',
  'reset:code:label': '验证码',
  'reset:code:placeholder': '验证码，可能在垃圾邮件中',
  'reset:code:required': '验证码不能为空',
  'reset:newpassword:label': '新密码',
  'reset:newpassword:placeholder': '密码必须至少包含6个字符',
  'reset:newpassword:required': '密码不能为空',
  'reset:newpassword:invalid': '密码必须至少包含6个字符',
  'reset:password2:label': '确认密码',
  'reset:password2:placeholder': '密码必须至少包含6个字符',
  'reset:password2:required': '确认密码不能为空',
  'reset:password2:invalid': '输入的密码不匹配',
  'reset:reset': '重置密码',
  'reset:back': '返回',
  'reset:login': '登录',
  'agree:click': '点击',
  'agree:text1': '即表示您同意我们的',
  'agree:text2': '和',
  'agree:terms': '服务条款',
  'agree:privacy': '隐私政策',
  'app:loading': '正在加载...',
  'app:welcome': '欢迎访问 xBack',
  'success:error': '第三次授权错误',
  'success:success': '成功！',
  'success:tryAgain': '再次尝试',
  'success:downloadTitle': '立即下载 xBack',
  'success:downloadText1': '享受会员邀请的 VIP 奖励。',
  'success:downloadText2':
    '您还可以邀请更多好友加入 xBack 社区，发现更多奖励！',
  'success:coming': '即将推出',
  'success:redirected': '如果您没有被自动转发',
  'copyright:copyright': '版权声明',
  'copyright:allRights': 'xBack Inc. 保留所有权利。',
  'download:title1': '下载 xBack',
  'download:title2': 'xBack 网络登录',
  'download:desc': '立即下载 xBack'
};
