export const ja = {
  'noCookie:title': 'Cookieが無効になっています',
  'noCookie:desc':
    'お使いのブラウザは Cookie を無効にしています。 Cookie が有効になっていることを確認して、もう一度お試しください。',

  'signup:google': 'Gmail でサインアップ',
  'signup:apple': 'pple でサインアップ',
  'signup:microsoft': 'Microsoft でサインアップ',

  'login:google': 'Gmail でログイン',
  'login:apple': 'Apple でログイン',
  'login:microsoft': 'Microsoft でログイン',
  'login:title': 'またはxBackアカウントでログイン',
  'login:email:placeholder': 'メールアドレス',
  'login:email:required': 'メールアドレスは空ではありません',
  'login:email:invalid': 'メールアドレスが無効です',
  'login:password:placeholder': 'xBack パスワード',
  'login:password:required': 'パスワードは空ではありません',
  'login:remember': '記憶する',
  'login:forget': 'パスワードをお忘れですか？',
  'login:signIn': 'サインイン',
  'login:cerateAccount': '新しいxBackアカウントを作成する',
  'login:expired': 'リンクの有効期限が切れています...',
  'login:access':
    'xBack アカウントにログインする場合は、アカウント情報へのアクセスを許可してください。',
  'signup:alert': 'アラート',
  'signup:codetip': '指定された招待コードは無効です。',
  'signup:continue': '続行',
  'signup:title': 'または新しい xBack アカウントを作成する',
  'signup:resend': '再送する',
  'signup:sendcode': '認証コードを送信',
  'signup:code:placeholder':
    '認証コードが迷惑メールに入っている可能性があります。',
  'signup:code:required': '認証コードは空白にできません。',
  'signup:password:placeholder': 'パスワードは8文字以上でなければなりません',
  'signup:password:required': 'パスワードは空白にできません',
  'signup:password:invalid': 'パスワードは8文字以上でなければなりません',
  'signup:inviteCode:placeholder': '招待コード（オプション）',
  'signup:inviteCode:no': '持っていない',
  'signup:inviteCode:has': '招待コードを持っている',
  'signup:signup': '登録する',
  'signup:hasAccount': 'アカウントをお持ちですか？今すぐサインイン',
  'reset:email:label': 'Eメール',
  'reset:email:placeholder': 'あなたのメールアドレス',
  'reset:email:required': 'メールアドレスは空ではありません',
  'reset:email:invalid': 'メールアドレスが無効です',
  'reset:resend': '再送する',
  'reset:sendcode': '認証コードを送信',
  'reset:code:label': '認証コード',
  'reset:code:placeholder':
    '認証コードが迷惑メールに入っている可能性があります。',
  'reset:code:required': '認証コードを空にすることはできません',
  'reset:newpassword:label': '新しいパスワード',
  'reset:newpassword:placeholder': 'パスワードは8文字以上でなければなりません',
  'reset:newpassword:required': 'パスワードを空にすることはできません',
  'reset:newpassword:invalid': 'パスワードは8文字以上でなければなりません',
  'reset:password2:label': 'パスワードの確認',
  'reset:password2:placeholder': 'パスワードは8文字以上でなければなりません',
  'reset:password2:required': '確認用パスワードは空白にできません。',
  'reset:password2:invalid': '入力されたパスワードが一致しない',
  'reset:reset': 'パスワードをリセットする',
  'reset:back': '戻る',
  'reset:login': 'サインイン',
  'agree:click': 'をクリックすることで',
  'agree:text1': 'をクリックすることで',
  'agree:text2': 'および',
  'agree:terms': '利用規約',
  'agree:privacy': 'プライバシーポリシー',
  'app:loading': '読み込み中',
  'app:welcome': 'xBackへようこそ',
  'success:error': '3回目の認証エラー',
  'success:success': '成功しました！',
  'success:tryAgain': 'もう一度試す',
  'success:downloadTitle': '今すぐ xBack をダウンロード',
  'success:downloadText1':
    '今すぐ xBack をダウンロードしてVIP特典をお楽しみください。',
  'success:downloadText2': 'xBack コミュニティに友達を招待することもできます！',
  'success:coming': '近日公開',
  'success:redirected': '自動的にリダイレクトされない場合',
  'copyright:copyright': '著作権',
  'copyright:allRights': 'xBack Inc. 無断複写・転載を禁じます。',
  'download:title1': 'xBackをダウンロード',
  'download:title2': 'xBack ウェブログイン',
  'download:desc': '今すぐxBackをダウンロード'
};
